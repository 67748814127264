import {z} from 'zod';

/**
 * LocalizedStrings type.
 * Represents all localized strings for a specific country and language.
 * These are strings that may be passed from liquid to the application for use in components.
 * Add new localized strings to this type as needed.
 */
export type LocalizedStrings = Partial<{
  /**
   * The best value label on the buy stack.
   */
  buyStackBestValue: string;
  /**
   * The label for the CTA button on first step of the buy stack.
   */
  buyStackCtaLabelFirst: string;
  /**
   * The label for the CTA button on second step of the buy stack.
   */
  buyStackCtaLabelSecond: string;
  /**
   * Label for the delivery frequency on the buy-stack.
   */
  buyStackDeliveryLabel: string;
  /**
   * The description for the the first option in the buy-stack frequency helper modal.
   */
  buyStackFrequencyDescriptionFirst: string;
  /**
   * The description for the the second option in the buy-stack frequency helper modal.
   */
  buyStackFrequencyDescriptionSecond: string;
  /**
   * The description for the the third option in the buy-stack frequency helper modal.
   */
  buyStackFrequencyDescriptionThird: string;
  /**
   * The header for the buy-stack frequency helper modal.
   */
  buyStackFrequencyHelperHeader: string;
  /**
   * Label for the button to open the frequency helper modal on the buy-stack.
   */
  buyStackFrequencyHelperLabel: string;
  /**
   * The title for the the first option in the buy-stack frequency helper modal.
   */
  buyStackFrequencyTitleFirst: string;
  /**
   * The title for the the second option in the buy-stack frequency helper modal.
   */
  buyStackFrequencyTitleSecond: string;
  /**
   * The title for the the third option in the buy-stack frequency helper modal.
   */
  buyStackFrequencyTitleThird: string;
  /**
   * The most popular label on the buy stack.
   */
  buyStackMostPopular: string;
  /**
   * Label for the 'One-Time Purchase' option in the buy stack.
   */
  buyStackOnetimePurchaseLabel: string;
  /**
   * The 'Save' portion of 'Save 10%' on the buy stack.
   */
  buyStackSaveLabel: string;
  /**
   * The first bullet point on the buy-stack subscribe option.
   */
  buyStackSubscribeBulletFirst: string;
  /**
   * The second bullet point on the buy-stack subscribe option.
   */
  buyStackSubscribeBulletSecond: string;
  /**
   * Label for the 'Subscribe' option in the buy stack.
   */
  buyStackSubscribeSaveLabel: string;
  /**
   * ie, Item
   */
  cartItem: string;
  /**
   * ie, Items
   */
  cartItemPlural: string;
  /**
   * The label for the 'Add to Cart' button on the collection buy stack.
   */
  collectionCtaButtonLabel: string;
  /**
   * The country ISO code. ie, 'US' for United States.
   */
  countryIsoCode: string;
  /**
   * The language ISO code. ie, 'en' for English.
   */
  languageIsoCode: string;
  /**
   * Root store route for the current locale
   */
  storeRootRoute: string;
  /**
   * ie, "day"
   */
  subscriptionUnitDay: string;
  /**
   * ie, "days"
   */
  subscriptionUnitDayPlural: string;
  /**
   * ie, "month"
   */
  subscriptionUnitMonth: string;
  /**
   * ie, "months"
   */
  subscriptionUnitMonthPlural: string;
  /**
   * ie, "week"
   */
  subscriptionUnitWeek: string;
  /**
   * ie, "weeks"
   */
  subscriptionUnitWeekPlural: string;
}>;

/**
 * Schema for parsing a Country object.
 * @see Country
 */
export const countrySchema = z.object({
  isoCode: z.string(),
  name: z.string(),
  currency: z.object({
    isoCode: z.string(),
    symbol: z.string(),
  }),
});

/**
 * Represents a country with its ISO code, name, and currency.
 * This is a partial object retrieved via liquid.
 *
 * @see {@link https://shopify.dev/docs/api/liquid/objects/country}
 */
export type Country = z.infer<typeof countrySchema>;

/**
 * Schema for parsing a ShopLocale object.
 * @see ShopLocale
 */
export const shopLocaleSchema = z.object({
  isoCode: z.string(),
  endonymName: z.string(),
});

/**
 * Represents a shop locale with its ISO code and name.
 * This is a partial object retrieved via liquid.
 * @see {@link https://shopify.dev/docs/api/liquid/objects/shop_locale}
 */
export type ShopLocale = z.infer<typeof shopLocaleSchema>;
